import store from '@/store';
import { loadView } from './_utils';
import queryString from 'query-string';
import launcherAPI from '@/modules/launcher';

const loginRoutes = [
    {
        path: 'login',
        name: 'login',
        component: loadView('login'),
        props: true,
        meta: {
            requiresNotLogin: !store.state.isApplication,
            coQuery: ['parental_flow_mode', 'simple_signup', 'marvel-machine'],
        }, // launcher should show login page even web session is valid due to launcher login failed
        beforeEnter(to, from, next) {
            if (store.state.channel === 'steam') {
                // steam connector shouldn't show login page
                store.dispatch('login/launcher/launcherLoginCancel');
            } else {
                next();
            }
        },
    },
    {
        path: 'login/parent',
        name: 'parent-login',
        component: loadView('login'),
        meta: { requiresNotLogin: true },
    },
    {
        path: 'logout',
        name: 'logout',
        component: loadView('logout'),
    },
    {
        path: 'login/tpa/:socialProvider?',
        name: 'login-tpa',
        component: loadView('login/login-tpa'),
        props: true,
        meta: { coQuery: ['parental_flow_mode'] },
    },
    {
        path: 'login/social-link/:case',
        name: 'login-social-link',
        component: loadView('social-link'),
        props: true,
        meta: { coQuery: ['parental_flow_mode', 'route_from'] },
    },
    {
        path: 'login/callback/:socialProvider',
        name: 'login-tpa-callback',
        alias: 'login/cb/:socialProvider', // for FB manual login flow issue
        beforeEnter: (to, _from, next) => {
            window.parent.postMessage('window.focus', '*'); //launcher
            // if (window.parent.win) window.parent.win.focus();
            let state = {};
            // to support a redirect mode of google oauth
            if (to.hash) {
                const params = new URLSearchParams(to.hash.slice(1));
                to.query.code = params.get('id_token') || params.get('access_token');
                to.query.state = params.get('state'); // to aggregate state from hash with state from query (apple -> query, google -> hash)
            }

            // Apple ID web login popup handler
            if (to.params.socialProvider === 'apple') {
                if (window.opener) {
                    window.opener.document.location.href = `${window.location.origin}/account${to.fullPath}`;
                    window.close();
                    return;
                }
                delete to.query.return_url; // apple keeps returning dummy return_url querystring which causes conflict issue with our return_url
            }

            if (to.query.state) {
                state = queryString.parse(decodeURIComponent(to.query.state));
                // transfer state to querystring except the value is referenced only in here
                for (const key in state) {
                    if (['actionType', 'clientCode'].includes(key)) continue;
                    if (state[key]) to.query[key] = state[key];
                }
                delete to.query.state;
            }
            if (to.query.twitter_state) {
                const twitter_state = queryString.parse(decodeURIComponent(to.query.twitter_state));
                for (const key in twitter_state) {
                    to.query[key] = state[key] = twitter_state[key];
                }
                delete to.query.twitter_state;
            }
            // app (launcher/steam connector)
            if (!launcherAPI.isAppMode() && state.clientCode) {
                return next({
                    replace: true,
                    name: 'login-tpa-callback-app',
                    params: {
                        ...to.params,
                        clientCode: state.clientCode,
                        callbackUrl: to.fullPath,
                    },
                });
            }

            if (to.params.socialProvider === 'twitter') {
                to.query.state = new URLSearchParams(new URL(location.href).search).get('state');
            }

            // console.log(to.query);
            const toRoute = {
                replace: true,
                name: 'login-tpa', // default without actionType
                params: to.params,
                query: to.query,
            };

            switch (state.actionType) {
                case 'socialLink':
                    toRoute.name = 'setting-social-link';
                    break;
                case 'global':
                    toRoute.name = 'global-login-callback-social';
                    toRoute.params.platform = state.platform;
                    break;
                case 'simpleGlobal':
                    toRoute.name = 'global-simple-login-callback-social';
                    break;
            }

            next(toRoute);
        },
    },
    {
        path: 'login/tpa-callback-app',
        name: 'login-tpa-callback-app',
        component: loadView('login/login-tpa-callback-app'),
        props: true,
    },
    {
        path: 'login/s-mode',
        name: 'smode',
        component: loadView('login/s-mode'),
        meta: { coQuery: ['parental_flow_mode'] },
    },
    {
        path: 'login/n-mode',
        name: 'nmode',
        component: loadView('login/n-mode'),
        meta: { coQuery: ['parental_flow_mode'] },
    },
    {
        path: 'login/success',
        name: 'success',
        component: loadView('login/success'),
    },
    {
        path: 'login/zendesk',
        name: 'zendesk',
        component: loadView('login/zendesk'),
    },
    {
        path: 'login/sso',
        name: 'sso',
        component: loadView('login/sso'),
    },
    {
        path: 'login/migration-tpa',
        name: 'migration-tpa',
        component: loadView('login/migration-tpa'),
    },
    {
        path: 'login/migration-complete',
        name: 'migration-complete',
        component: loadView('login/migration-complete'),
    },
    {
        path: 'login/auth-types',
        name: 'auth-types-2fa-login',
        component: loadView('login/auth-types-2fa'),
        props: true,
    },
    {
        path: 'login/verify-device',
        name: 'verify-device',
        component: loadView('login/verify-device'),
    },
    {
        path: 'login/verify-code',
        name: 'login-verify-2fa',
        component: loadView('login/verify-code-2fa'),
        meta: { coQuery: ['parental_flow_mode'] },
        props: true,
    },
    {
        path: 'login/verify-backup-code',
        name: 'login-verify-2fa-backup-code',
        component: loadView('login/verify-code-2fa'),
        props: true,
        meta: { coQuery: ['parental_flow_mode'] },
    },
    {
        path: 'login/verify-account',
        name: 'login-verify-email',
        component: loadView('login/verify-email'),
        props: true,
        meta: { coQuery: ['parental_flow_mode'] },
    },
    {
        path: 'login/verify-sms',
        name: 'login-verify-sms',
        component: loadView('login/verify-code-sms'),
        props: true,
        meta: { coQuery: ['parental_flow_mode'] },
    },
    {
        path: 'login/move-to-zendesk',
        name: 'login-move-to-zendesk',
        component: loadView('login/move-to-zendesk'),
    },
];

export default loginRoutes;
