<template>
    <div :class="classes">
        <IconSprite v-if="checkRegionOne" class="hide"></IconSprite>

        <div v-if="notificationMsg" id="errorContainer" class="alert alert-danger text-center" role="alert">
            <div class="error-section">
                <SvgIcons icon-holder-class="icon-warning" icon-id="icon-warning" />
                <!-- TODO: v-html can lead to XSS attacks and should be avoided  -->
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="notificationMsg[$i18n.locale] || notificationMsg[$i18n.fallbackLocale]"></div>
            </div>
        </div>

        <div v-if="$store.state.loading" class="modal">
            <div class="page-spinner-wrapper">
                <span class="spinner-circle"></span>
            </div>
        </div>

        <transition v-hide="$store.state.loading" appear name="fade" mode="out-in">
            <router-view class="body"></router-view>
        </transition>

        <dialogs-wrapper transition-name="fade"></dialogs-wrapper>
        <globalFooter v-if="isShowFooter"></globalFooter>

        <!-- Dev info -->
        <DevInfo
            service-name="Account"
            :route-name="$route.name"
            :environment="environment"
            :current-version="currentVersion"
            :channel="channel"
            :cdn-host="cdnHost"
        />
    </div>
</template>

<script>
// import { uuid } from 'vue-uuid';
import { IconSprite, SvgIcons } from '@arena/toolkit';
import { DevInfo } from '@arena/toolkit';
import globalFooter from '@/components/global-footer';
import launcherAPI from '@/modules/launcher';
import { mapState, mapActions } from 'vuex';
import { hasFromOPENAPI_WEBSITE } from '@/utils/website-checker';

export default {
    name: 'App',
    components: {
        globalFooter,
        IconSprite,
        SvgIcons,
        DevInfo,
    },
    metaInfo() {
        return {
            title: this.$t('account.title'),
            meta: [
                { name: 'description', content: this.$t('account.meta-desc') },
                { name: 'keywords', content: this.$t('account.meta-keyword') },
                { property: 'og:site_name', content: this.$t('account.title') },
                { property: 'og:type', content: 'website' },
                { property: 'og:url', content: 'http://www.nexon.com/' },
                { property: 'og:title', content: this.$t('account.title') },
                { property: 'og:description', content: this.$t('account.meta-desc') },
                {
                    property: 'og:image',
                    content: 'https://web.nxfs.nexon.com/nexon-logo.png',
                },
                { itemprop: 'name', content: this.$t('account.meta-title') },
                { itemprop: 'description', content: this.$t('account.meta-desc') },
                {
                    itemprop: 'image',
                    content: 'https://web.nxfs.nexon.com/nexon-logo.png',
                },
                {
                    'http-equiv': 'Content-Security-Policy',
                    // https://braintree.github.io/braintree-web-drop-in/docs/current/#content-security-policy
                    content: `default-src *.nexon.com *.nexon.io *.nexon.net *.google-analytics.com 'self' gamescale: toy:;
						connect-src *.nexon.com *.nexon.net *.nexon.io *.google.com *.serving-sys.com *.google-analytics.com *.iesnare.com *.braintreegateway.com *.braintree-api.com *.cardinalcommerce.com *.exmox.net *.go2cloud.org *.gwmtracking.com *.doubleclick.net *.trakgamebassadors.com *.playmmogames.com *.bobtrk.com *.xentrk.net *.go2cloud.org *.casreader215.com *.trkn.us *.adnxs.com *.flashtalking.com *.hiasor.com *.goplay4.com *.play5r.com *.tiktok.com *.quantserve.com *.quantcount.com *.gsght.com *.trkppc.com *.playreg.net *.cpmstar.com https://twitter.com https://cdn.cookielaw.org https://geolocation.onetrust.com 'self' wss: data: gamescale: toy:;
						script-src *.nexon.com *.nexon.net *.hotjar.com *.braintreegateway.com *.cardinalcommerce.com https://static.ads-twitter.com https://sc-static.net https://js.adsrvr.org *.recaptcha.net *.google-analytics.com *.googletagmanager.com *.google.com *.facebook.net *.gstatic.com *.gstatic.cn *.iesnare.com *.googleadservices.com *.doubleclick.net *.serving-sys.com https://rexmox.com *.sc-static.net *.twitter.com *.rexmox.com *.exmox.net *.go2cloud.org *.gwmtracking.com *.doubleclick.net *.trakgamebassadors.com *.playmmogames.com *.bobtrk.com *.xentrk.net *.go2cloud.org *.casreader215.com *.trkn.us *.adnxs.com *.flashtalking.com *.googleapis.com *.redditstatic.com *.tiktok.com *.googleusercontent.com *.reddit.com *.ipstatp.com *.topbuzz.com *.hiasor.com *.goplay4.com *.play5r.com *.ibytedtos.com *.quantserve.com *.quantcount.com *.gsght.com *.trkppc.com *.playreg.net *.cpmstar.com https://cdn.cookielaw.org 'unsafe-eval' 'unsafe-inline' 'self' gamescale: toy:;
						style-src *.nexon.com *.google.com *.googleapis.com *.braintreegateway.com *.cloudflare.com 'unsafe-inline' gamescale: toy:;
						img-src *.nexon.com *.google.com *.google-analytics.com *.hotjar.com *.braintreegateway.com *.yahoo.com *.rubiconproject.com *.casalemedia.com *.agkn.com *.tremorhub.com https://soma.smaato.net https://pixel.advertising.com https://sync.search.spotxchange.com *.rfihub.com *.adnxs.com *.cloudflare.com https://gwmtracking.com https://www.googletagmanager.com *.gstatic.com *.gstatic.cn *.doubleclick.net *.go2cloud.com *.revenueuniverse.com *.gamebassadors.network *.playmmogames.com *.ad4game.com *.exmox.net *.trkppc.com *.go2cloud.org *.hqtrk.com *.sharethis.com *.exmox.net *.go2cloud.org *.gwmtracking.com *.doubleclick.net *.trakgamebassadors.com *.playmmogames.com *.bobtrk.com *.xentrk.net *.go2cloud.org *.casreader215.com *.trkn.us *.facebook.com *.flashtalking.com *.googleapis.com *.redditstatic.com *.tiktok.com *.googleusercontent.com *.reddit.com *.ipstatp.com *.topbuzz.com *.hiasor.com *.goplay4.com *.play5r.com *.ibytedtos.com *.quantserve.com *.quantcount.com *.gsght.com *.playreg.net https://gsght.com *.trkppc.com *.cpmstar.com https://cdn.cookielaw.org 'self' data: gamescale: toy:;
						font-src *.nexon.com *.google.com *.facebook.com 'self' gamescale: toy:;
                        frame-src *.nexon.com *.nexon.net *.google.com *.hotjar.com *.braintreegateway.com *.cardinalcommerce.com https://tr.snapchat.com *.facebook.com https://www.trakgamebassadors.com/ https://trk.gamebassadors.network/ https://js.adsrvr.org *.gwmtracking.com *.serving-sys.com *.ad4game.com *.rexmox.com *.trkppc.com *.playmmogames.com *.ad4game.com *.exmox.net *.playmmogames.com *.doubleclick.net *.trkn.us *.adnxs.com *.flashtalking.com *.googleapis.com *.redditstatic.com *.tiktok.com *.googleusercontent.com *.reddit.com *.ipstatp.com *.topbuzz.com *.hiasor.com *.goplay4.com *.play5r.com *.tiktok.com *.quantserve.com *.quantcount.com *.gsght.com *.trkppc.com *.playreg.net *.cpmstar.com https://recaptcha.net/  gamescale: toy:;
                        media-src *.iesnare.com *.hiasor.com *.goplay4.com *.play5r.com *.tiktok.com data: gamescale: toy:`,
                },
            ],
        };
    },
    data() {
        return {
            supportRegions: ['global', 'jp', 'kr', 'sea', 'tw', 'th'],
            supportRegionOnly: [],
            hideFooter: true,
            notificationMsg: null,
            selectedGameData: null,
            windowWidth: 0,
        };
    },
    computed: {
        ...mapState(['gameList']),
        showDevInfo() {
            return !this.$store.state.isMobile || this.environment !== 'prod';
        },
        cdnHost() {
            return process.env.VUE_APP_CDN_HOST;
        },
        currentVersion() {
            return process.env.VUE_APP_VERSION;
        },
        environment() {
            return process.env.VUE_APP_ENV;
        },
        checkRegionOne() {
            return environment.region === 'one';
        },
        classes() {
            return [this.channel, this.region, this.language, { ieMode: this.isIE }];
        },
        maintenance() {
            return this.$store.state.configurations.maintenance;
        },
        isToy() {
            return this.$store.state.appType === 'toy' || this.$store.state.appType === 'toy_region';
        },
        isToyCommunity() {
            return this.$store.state.appType === 'toy_community';
        },
        isDevelopers() {
            return this.$store.state.appType === 'developers';
        },
        regionDataLayer() {
            const { region } = this;
            return region === 'global' ? 'west' : region;
        },
        language() {
            return this.$i18n.locale;
        },
        channel() {
            if (launcherAPI.isLauncher()) return 'launcher';
            if (launcherAPI.isSteamConnector()) return 'steam';
            if (this.isToy) return 'toy';
            if (this.isToyCommunity) return 'toy_community';
            if (this.isDevelopers) return 'developers';
            return 'web';
        },
        isIE() {
            return this.$store.state.isIE;
        },
        isShowFooter() {
            const { landing } = this.$store.state;
            return (
                !this.$route.meta.hideFooter &&
                ((this.isLauncher && !this.$store.state.login.isLoggedIn) ||
                    this.isToy ||
                    this.isToyCommunity ||
                    this.isDevelopers ||
                    landing)
            );
        },
    },
    watch: {
        supportRegionOnly() {
            if (this.supportRegionOnly.length <= 0) {
                return;
            }
            const clearHandler = setInterval(() => {
                const footerEle = document.querySelector('.footer-top');
                if (footerEle && this.supportRegionOnly.length) {
                    clearInterval(clearHandler);
                    this.supportRegions
                        .filter((region) => !this.supportRegionOnly.includes(region))
                        .forEach((region) => {
                            footerEle.classList.add(`hide-${region}`);
                        });
                }
            }, 100);
        },
        windowWidth() {
            this.gameCustomization();
        },
        hideFooter(isHide) {
            if (!isHide) {
                return;
            }

            const clearHandler = setInterval(() => {
                const footerEle = document.querySelector('.footer-top');
                if (footerEle) {
                    footerEle.style.display = isHide ? 'none' : 'block';
                    clearInterval(clearHandler);
                }
            }, 100);
        },
    },
    async created() {
        this.maintenanceModeRedirect();
        this.storeDirectReturn();
        this.storeChannel();
        this.getNotification();
        await this.getGameList()
            .then(await this.processGameList)
            .then(await this.gameCustomization);
    },
    mounted() {
        // OPENAPI 처리 이슈
        if (hasFromOPENAPI_WEBSITE()) {
            if (typeof window.sessionStorage !== 'undefined') {
                window.sessionStorage.setItem('from_openapi_website', 'true');
                this.hideFooter = true;
                this.supportRegionOnly.push('sea');
                this.supportRegionOnly.push('global');
            }
        } else {
            if (typeof window.sessionStorage !== 'undefined') {
                window.sessionStorage.removeItem('from_openapi_website');
                this.hideFooter = true;
            }
        }

        window.version = `Current Version => ${this.currentVersion}`;
        this.$nextTick(() => {
            window.addEventListener('resize', this.setWindowWidth);

            this.setWindowWidth();
        });
        setTimeout(() => {
            if (launcherAPI.isAppMode() || this.checkRegionOne) {
                const otAccept = document.getElementById('onetrust-accept-btn-handler');
                otAccept ? otAccept.click() : null;
            }
        }, 500);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setWindowWidth);
    },
    async updated() {
        this.gameCustomization();
        dataLayer.push({
            channel: this.channel,
            region: this.regionDataLayer,
            language: this.language,
        });
    },
    methods: {
        ...mapActions(['getGameList']),
        isLauncher: launcherAPI.isLauncher,
        isSteamConnector: launcherAPI.isSteamConnector,
        getNotification() {
            if (!launcherAPI.isSteamConnector()) {
                this.$cdnAPI.get('/web/v2/{regionCode}/notifications.json').then(({ data }) => {
                    const notification = data.find((field) => field.Name === 'Account');
                    if (notification) {
                        this.notificationMsg = notification.Message;
                    }
                });
            }
        },
        maintenanceModeRedirect() {
            if (this.maintenance) {
                this.$router.push({ name: 'maintenance' });
            }
        },
        processGameList() {
            const prodId = this.$cookie.get('ProdId');
            this.selectedGameData = prodId ? this.gameList.find((game) => game.productID === prodId) : null;
        },
        setWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
        },
        async gameCustomization() {
            const account = document.getElementById('account'),
                currentRoute = this.$route.name,
                { landing } = this.$store.state,
                gameData = await this.selectedGameData;

            account.classList.add(environment.region);
            account.classList.remove('marvel-machine');

            if (currentRoute === 'login' || currentRoute === 'create-account') {
                const prodId = this.$cookie.get('ProdId');
                if (this.isFromMarvelMachine) {
                    account.classList.add('marvel-machine');
                } else if (gameData && gameData.gameAccountBackground && !landing) {
                    if (this.windowWidth > 740) {
                        if (gameData.gameAccountBackgroundWide) {
                            account.style.backgroundImage = `url(//${this.cdnHost}${gameData.gameAccountBackgroundWide})`;
                        } else {
                            account.style.backgroundImage = `url(//${this.cdnHost}${gameData.gameAccountBackground})`;
                        }
                    } else {
                        account.style.backgroundImage = `url(//${this.cdnHost}${gameData.gameAccountBackground})`;
                    }
                    if (gameData.gameAccountBackgroundColor) {
                        account.style.backgroundColor = gameData.gameAccountBackgroundColor;
                    }
                }
                if (gameData && gameData.gameLandingBackground && landing) {
                    if (this.windowWidth > 740) {
                        if (gameData.gameLandingBackgroundWide) {
                            account.style.backgroundImage = `url(//${this.cdnHost}${gameData.gameLandingBackgroundWide})`;
                        } else {
                            account.style.backgroundImage = `url(//${this.cdnHost}${gameData.gameLandingBackground})`;
                        }
                    } else {
                        account.style.backgroundImage = `url(//${this.cdnHost}${gameData.gameLandingBackground})`;
                    }
                }
                if (landing) account.classList.add('game-landing');
                if (prodId) {
                    account.classList.add('has-prod-id');
                } else {
                    account.classList.add('general-bg');
                }
            } else {
                this.storeChannel();
            }
        },
        storeDirectReturn() {
            if (this.$route.query.direct_return) {
                this.$store.commit('setDirectReturn', this.$route.query.direct_return);
            }
        },
        storeChannel() {
            const account = document.getElementById('account');
            account.classList.add(this.channel);
            this.$store.commit('setChannel', this.channel);
        },
    },
};
</script>

<style lang="scss" global>
#globalFooter {
    .footer-top {
        &.hide-jp {
            .languages li a.jp {
                display: none;
            }
        }
        &.hide-kr {
            .languages li a.kr {
                display: none;
            }
        }
        &.hide-global {
            .languages li a.global {
                display: none;
            }
        }
        &.hide-tw {
            .languages li a.tw {
                display: none;
            }
        }
        &.hide-th {
            .languages li a.th {
                display: none;
            }
        }
        &.hide-sea {
            .languages li a.sea {
                display: none;
            }
        }
    }
}
</style>

<style lang="less">
@import '~@arena/toolkit/src/less/toolkit/toolkit.less';
@import './less/global';
@import './less/responsive';

#errorContainer {
    margin-bottom: 1px;
}
.error-section {
    display: flex;
    justify-content: center;
    p {
        margin-bottom: 0;
        padding-top: 2px;
    }
}
</style>
