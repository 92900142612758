import router from '@/router';

export default {
    namespaced: true,
    state: {
        data: {
            authTypes: [],
            phoneNo: '',
            primaryAuthType: '',
            email: '',
        },
        globalAuthInfo: {
            authTypes: [],
            phoneNo: '',
        },
        tpa: {
            authTypes: [],
            phoneNo: '',
            primaryAuthType: '',
            email: '',
        },
    },
    actions: {
        async getAuthTypes({ rootState, commit }) {
            const isUnified = router.currentRoute.query.unified || router.currentRoute.name.indexOf('unified') > -1,
                data = await this._vm.$accountAPI.get(`/no-auth/${isUnified ? 'unified/' : ''}mfa/auth-types`, {
                    params: { email: rootState.login.loginId },
                });
            commit('setData', data);
        },
        async getGlobalAuthTypes({ commit }, { email }) {
            const data = await this._vm.$globalAccountAPI.get('no-auth/global/mfa/auth-types', {
                params: { email },
            });
            commit('setGlobalAuthInfo', data);
        },
        async getTpaAuthTypes({ commit }) {
            const isUnified = router.currentRoute.query.unified || router.currentRoute.name.indexOf('unified') > -1;
            const data = await this._vm.$accountAPI.get(`/no-auth/${isUnified ? 'unified/' : ''}mfa/tpa/auth-types`);
            commit('setTpaAuthInfo', data);
        },
    },
    mutations: {
        setData(state, data) {
            state.data = data;
        },
        setGlobalAuthInfo(state, data) {
            state.globalAuthInfo = data;
        },
        setTpaAuthInfo(state, data) {
            state.tpa = data;
        },
    },
};
